import React, {useContext} from 'react';
import {Banner, CenterTitle, HeaderStudio, Team, CarouselArtist} from "../../components";
import {AppContext} from "../../context/AppContext";
import styles from "./Studio.module.css";
import Services from "../../components/Pages/Studio/Services/Services";
import bgStudioA from '../../data/img/studio/header.webp';
import bgStudioB from '../../data/img/studio/header1.webp';
import bgHeader from '../../data/img/studio/bgorso.webp';
import logoSony from '../../data/img/customers/sony.png';
import logoUMG from '../../data/img/customers/umg.png';
import logoWarner from '../../data/img/customers/warner.png';
import logoThaurus from '../../data/img/customers/thaurus.png';
import logoThamsanqa from '../../data/img/customers/thamsanqa.png';
import logoA1 from '../../data/img/customers/a1.png';
import logoFMC from '../../data/img/customers/fmc.png';
import logoOrangle from '../../data/img/customers/orangle.png';
import schedaRegiaA from "../../data/regiaA.pdf";
import schedaRegiaB from "../../data/regiaB.pdf";
import videoBg from "../../data/videos/header-video.mp4";

const Studio = () => {
    const appData = useContext(AppContext);
    return (
        <>
            <helmet>
                <title>
                    Underbear Studio (Milano)
                </title>
                <meta name="description" content="Underbear Studio si trova a Milano. Sede di lavoro dell'etichetta discografica AAR Music, è composto da 2 regie indipendenti trattate acusticamente. Prenota la tua sessione o inviaci gli stems per un mix e mastering online" />
            </helmet>
            <div className={styles.container} style={{backgroundColor: `#000000`, marginTop:`-20vh`}}>
                {/* <video src={videoBg} autoPlay loop muted playsInline /> */}
                {/* <img style={{width: `100%`}} src={bgHeader} /> */}
                <div className={styles.text} style={{color: `#fff`, paddingTop:'200px'}}>
                    <h1>Underbear</h1>
                    <h2>Studio di registrazione a Milano</h2>
                    <p>
                        Hai mai desiderato dare vita alle tue idee musicali in un ambiente professionale e accogliente? Benvenuto all'<a href="https://www.instagram.com/underbearstudio" target="_blank">Underbear Studio</a> di Milano, il luogo dove la tua creatività prende forma nel cuore pulsante della città. Siamo più di un semplice studio; siamo artisti, tecnici e appassionati che condividono la tua stessa passione per la musica. Lo studio è anche la sede operativa della label indipendente <a href="https://www.instagram.com/aarmusiclabel" target="_blank">AAR Music</a>, che ha qui il suo ufficio.
                    </p>
                    <center>
                        <h2>Credits</h2>
                        <h3><i>Madame, Alfa, Leon Faun, Michele Bravi, Dani Faiv, Promessa e tanti altri</i></h3>
                    </center>
                    <p>
                        Ascolta questi ed altri artisti nella playlist con tutti i brani a cui abbiamo lavorato:
                    </p>
                    <iframe style={{borderRadius:`12px`}} src="https://open.spotify.com/embed/playlist/705dqFj39DvVOeQE9MHkLb?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                </div>
                <div style={{background: 'rgb(255,255,255)', padding: '30px 5px'}}>
                    <center>
                        <h2>I nostri producers & engineers</h2>
                    </center>
                    <CarouselArtist artists={appData.rosterList.artists} isProducer="true"/>
                </div>
                <center style={{color: `#fff`, paddingTop:'20px'}}>
                    <h2>Clienti</h2>    
                    <img style={{width: `100px`, margin: `0px 15px 10px 20px`}} src={logoUMG} />
                    <img style={{width: `60px`, margin: `0px 20px`}} src={logoThaurus} />
                    <img style={{width: `80px`, margin: `0px 20px 10px 20px`}} src={logoWarner} />
                    <img style={{width: `60px`, margin: `0px 10px`}} src={logoSony} />
                    <img style={{width: `100px`, margin: `0px 20px 18px 20px`}} src={logoThamsanqa} />
                    <img style={{width: `100px`, margin: `0px 20px 0px 20px`}} src={logoA1} />
                    <img style={{width: `80px`, margin: `0px 10px 5px 10px`}} src={logoFMC} />
                    <img style={{width: `60px`, margin: `0px 10px 5px 10px`}} src={logoOrangle} />
                </center>
            </div>
            <HeaderStudio title={"Room A"} linkTitle={schedaRegiaA} bgImage={bgStudioA} margin={"-20vh"}/>
            <div className={styles.black}/>
            <HeaderStudio title={"Room B"} linkTitle={schedaRegiaB} bgImage={bgStudioB} margin={"0"}/>
            <div style={{color: `#fff`}} className={styles.textlocation}>
                <div className={styles.mappina} style={{float: `left`, marginRight:`10vh`, marginBottom:`10vh`}}><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2796.569716717762!2d9.200626012130682!3d45.49860887095382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c7c8a9a12aab%3A0xf713489f7acf9cd5!2sUnderbear%20Studio!5e0!3m2!1sit!2sit!4v1728406595633!5m2!1sit!2sit" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
                <div>
                    <h2>Studio di registrazione in zona centrale a Milano (come raggiungerci, dove siamo)</h2>
                    <p><b>Underbear Studio</b> si trova in <a href="https://maps.app.goo.gl/Azc1rUbKVixSAMHLA" target="_blank">via Dario Papa 4, Milano (MI)</a>. </p><p>La stazione della metropolitana più vicina è <b>Istria</b> (Metro Lilla), oppure si può arrivare in 15 minuti a piedi scendendo a <b>Sondrio</b> (Metro Gialla).</p>
                </div>                
            </div>
            <center style={{paddingTop: `30px`}}>
                <h2>Servizi</h2>    
            </center>
            <Services/>
        </>
    );
};

export default Studio;
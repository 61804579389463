import React, {useContext, useEffect, useRef, useState} from 'react';
import styles from "./Services.module.css";
import {Banner, Button, CenterTitle, Form, ServiceCard} from "../../../index";
import {AppContext} from "../../../../context/AppContext";
import useMediaQuery, {Device} from "../../../../hooks/useMediaQuery";
import Carousel from "../../../ElasticCarousel";
import {Link} from "react-router-dom";


const Services = () => {
    const appData = useContext(AppContext);
    const isMobile = useMediaQuery(Device.sm);
    const isTablet = useMediaQuery(Device.md);
    const [idService, setIdService] = useState(-1);
    const ref = useRef(null);
    const [showArrow, setShowArrow] = useState()

    const breakPoints = [
        {width: 1, itemsToShow: 1},
        {width: 550, itemsToShow: 1},
        {width: 768, itemsToShow: 1},
        {width: 1200, itemsToShow: 4},
    ];

    useEffect(() => {
        if (isMobile) {
            setShowArrow(false);
        } else {
            setShowArrow(true)
        }
    }, [isMobile, idService])


    return (
        <>

            {/* <CenterTitle children={"SERVIZI"}/> */}
            <div className={styles.text}>
                    <center>
                        Prenota una <b>chiamata gratuita</b> per chiedere info o un preventivo specifico
                        <a href="https://calendly.com/underbearstudio/30min">
                            <Button type="button" class="green">Prenota</Button>
                        </a>
                        <br />
                        Altrimenti scegli il servizio di cui hai bisogno tra i seguenti:
                    </center>
                </div>
            {!isMobile && !isTablet ? <div className={styles.banner}>
                    <Banner rigth={false} colorBg={appData.color.grey} height={"50rem"} bgSize={"4rem"} bgPosY={"bottom"}>
                        <div className={styles.container}>
                            {appData.servicesList.map((x, i) => {
                                return (

                                    <ServiceCard img={x.img} name={x.name} text={x.description} tag={x.tag}
                                                 onChangeService={setIdService} id={i} reference={ref} selected={idService}/>
                                )
                            })}
                        </div>

                    </Banner>
                </div> :
                <div className={styles.carousel}>
                    <Carousel showArrows={showArrow} breakPoints={breakPoints}>
                        {appData.servicesList.map((x, i) => {
                            return (
                                <>
                                    <ServiceCard key={i} img={x.img} name={x.name} text={x.description}
                                                 onChangeService={setIdService} selected={idService} id={i} reference={ref} />
                                </>
                            )
                        })}
                    </Carousel>
                </div>
            }
            <div ref={ref}>
                {idService > -1 ? <Form type={"services"} item={appData.servicesList[idService]}/>: null}
            </div>


        </>
    );
};

export default Services;